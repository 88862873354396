const physiotherapyCopy = {
  about: {
    de: {
      title: 'Physiotherapie',
      body: [
        'Meine duale Ausbildung in Sportwissenschaften und Physiotherapie erlaubt mir auch abseits der Yogamatte eine für mich noch wichtigere Brücke zu bilden: nämlich jene zwischen Wissenschaft und Menschlichkeit.',
        'Weil ich schon immer auch mit Athlet*innen arbeiten wollte, habe ich mich dazu entschlossen, als Physiotherapeut der Leistungssportler*innen des American Football Teams der Vienna Vikings zu arbeiten. Abseits vom Nervenkitzel der Wikingerkriege bin ich jedoch auch an orthopädischen Problematiken und an der Behandlung von chronischen Schmerzen interessiert. Egal ob Spitzensportler*in oder Bürohengst*stute mit Rückenbeschwerden, ich freue mich darauf mit euch zusammen den ersten Schritt in ein noch besseres Leben zu gehen.'
      ]
    },
    en: {
      title: 'Physiotherapy',
      body: [
        'My dual education in sports science and physical therapy allows me to build a bridge beyond the yoga mat: the one between science and humanity.',
        'Because I have always wanted to work with athletes, I decided to work as a physiotherapist for the athletes of the American Football Team of the Vienna Vikings. Apart from the thrill of the Vikings wars, I am also interested in orthopedic problems and the treatment of chronic pain. Whether you are a top athlete or an office stallion*mare with back problems, I look forward to taking the first step towards an even better life together with you.'
      ]
    }
  },
  whatnext: {
    de: {
      title1: 'Wie könnt ihr euch die Therapie vorstellen?',
      body1: [
        'Erstes Kennenlernen, Untersuchung und Interview',
        'Aufklärung über die Therapie, die Krankheit und psychosoziale Faktoren',
        'Einigung über das weitere Vorgehen und eure Ziele',
        'Körperliches Training / Erstellung eines Übungsprogrammes / Regelmäßige Bestandsaufnahme',
        'Therapieabschluss bei dem ihr mir Kuchen bringt und mich im Anschluss an eure Freund*innen empfiehlt'
      ],
      title2: 'Was benötigt ihr?',
      body2: [
        'Verordnung zur Physiotherapie von einem*einer Arzt*Ärztin für 45 Minuten Physiotherapie',
        'Befunde und ärztliche Diagnosen',
        'Sportkleidung'
      ]
    },
    en: {
      title1: 'What does the therapy look like?',
      body1: [
        'First meeting, examination and interview',
        'Explanation about the therapy, the disease and psychosocial factors',
        'Agreement on how to proceed and your goals',
        'Physical training / creation of an exercise program / regular assessment of your condition',
        'End of therapy where you bring me cake and recommend me to your friends'
      ],
      title2: 'What do you need?',
      body2: [
        'A prescription for physical therapy from a doctor for 45 or 60 minutes of physical therapy',
        'Findings and medical diagnoses',
        'Sportswear'
      ]
    }
  },
  info: {
    de: {
      title1: 'Lieber ganzheitlich als steinzeitlich',
      body1: [
        'Bewegung ist ein Grundbedürfnis des menschlichen Körpers und zudem ein fixer Bestandteil meines Lebens. Deshalb wird die Therapie zu einem großen Teil aus Bewegung bestehen. Krafttraining bietet hier die einfachste und sicherste Methode, um nicht nur einen großen Bizeps zu bekommen, sondern auch, um Schmerzen zu lindern und den Körper wieder belastbar zu machen. Krafttraining nicht die einzige Methode, um Bewegungstherapie zu machen, jedoch die einzige Methode, um einen großen Bizeps zu bekommen. Spaß beiseite, die Therapie wird so auf euch angepasst, dass ihr auch als Sportmuffel gefordert aber nicht überfordert werdet.',
        'Es sollte in jeder physiotherapeutischen Behandlung Standard sein, dass auch biopsychosoziale Faktoren beachtet werden. Diese stellen die verschiedensten Belastungen dar (), welche auf jeden Menschen tagtäglich, oder über eine längere Zeit einwirken. Da sie maßgeblich unser Gehirn und unser Nervensystem beeinflussen, sollten sie in der Therapie unbedingt berücksichtigt werden. Man könnte also sagen, dass mein Therapieansatz immer „ganzheitlich“ ist, auch wenn ich dieses Wort lieber vermeiden würde.',
        'Gemeinsam werden wir herausfinden, welche Faktoren euer Krankheitsbild beeinflussen und wie ihr euch diese zu Nutzen machen könnt. Ich werde euch dabei helfen euer Krankheitsbild zu verstehen, damit ihr wisst, wie ihr damit umgehen müsst und wie ein wunderschöner Schmetterling eure frisch geheilten und muskulösen Flügel zu entfalten, um in ein beschwerdefreies Leben zu fliegen.'
      ],
      title2: 'Mein Weg von BWL zu BWgung',
      body2: [
        'Als Teenager spielte ich mit Begeisterung Computer, doch dann tauschte ich Keyboard gegen Kurzhantel und probierte eine Vielzahl an Sportarten aus: Vom Tanzen zu Teamsport über Akrobatik bis hin zum Kampfsport habe ich mich in allem versucht. Zunächst war Sport für mich nur eine Möglichkeit der Ablenkung vom für mich uninteressanten Wirtschaftsstudium. Mit wenig Enthusiasmus begann ich dieses, und schlug mich so durch wie früher beim Kampfsport, doch dann kam etwas, mit dem ich nicht gerechnet habe: Die langeweiligste Mathematikprüfung der Welt. Diese führte dazu, dass ich einen Weg einschlug, der mich mit Zahlen nur bei der Zeitmessung meines 100 Meter Sprints in Berührung kommen lassen würde.',
        'Ich begann ein Sportstudium, in dem ich meine Bewegungserfahrungen erweitern und zudem eine trainingswissenschaftliche Einsicht in den Gesundheitsbereich erlangen konnte. Außerdem konnte ich während eines Auslandsjahrs in Australien, die Sprungleistung einiger Kangurus verbessern. Um meine Kenntnisse der medizinischen Aspekte von Training zu erweitern, habe ich nach dem Abschluss des Sportstudiums auch ein Physiotherapiestudium abgeschlossen.',
        'Der Gedanke, Menschen mit akuten Verletzungen und chronischen Schmerzen zurück ins Leben zu bringen, treibt mich weiterhin an mein Wissen zu vertiefen. Zusammen mit euch möchte ich herausfinden, wie ihr am schnellsten euren besten Zustand erreichen könnt. Dafür bin ich für euch Physiotherapeut, Trainer, Motivator, Berater und ganz selten vielleicht sogar mal Masseur.'
      ]
    },
    en: {
      title1: 'Rather holistic than anachronistic',
      body1: [
        'Movement is a basic need of the human body and also a vital part of my life. Therefore, a large part of the therapy will consist of movement. Strength training offers the easiest and safest method, not only to get big biceps, but also to relieve pain and make the body resilient again. Strength training is not the only method to do exercise therapy; however it is the only method to get big biceps. All kidding aside, the therapy is customized to you so that even if you are a gym grouch, you will be challenged but not overwhelmed.',
        'It should be standard in any physiotherapy treatment that biopsychosocial factors are also taken into account. These are the various stresses, which affect every person every day, or over a long period of time. Since they have a significant influence on our brain and nervous system, they should definitely be taken into account in therapy. So you could say that my therapy approach is always "holistic", although I would rather avoid this word.',
        'Together we will find out which factors influence your clinical picture and how you can make use of them. I will help you to understand your disease pattern so that you know how to deal with it and, like a beautiful butterfly, unfold your newly healed and muscular wings to fly into a symptom-free life.'
      ],
      title2: 'Choosing fitness and health over business and wealth',
      body2: [
        `At first, sports were just a way for me to distract myself from my business studies, which I found utterly dull. My start into the world of economics was with little enthusiasm, but I tried to punch through as I used to do in martial arts, but then something occured that I didn‘t expect; the most boring mathematics exam in the world. This let me down a path that would only require me to count when timing my 100 meter sprint.`,
        'I began studying sports, which allowed me to expand my movement experience and also gain an exercise science-based insight into the health field. Also, during a year abroad in Australia, I was able to improve the jumping performance of some kangaroos. To expand my knowledge of the medical aspects of training, I also completed a physiotherapy degree after graduating with a degree in physical education.',
        'The idea of bringing people with acute injuries and chronic pain back to life continues to drive me to deepen my knowledge. Together with you I want to find out how you can reach your best condition the fastest. For this I am your physiotherapist, trainer, motivator, advisor and very rarely maybe even a masseur.'
      ]
    }
  }
}

export default physiotherapyCopy