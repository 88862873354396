import React from 'react'
import Layout from '../components/Layout'
import AboutTrainer from '../components/individual/AboutTrainer'
import WhatNext from '../components/individual/WhatNext'
import AdditionalInfo from '../components/individual/AdditionalInfo'
import img from '../images/lasse.jpg'
import physiotherapyCopy from '../data/physiotherapyCopy'
import bottomImg from '../images/physio-bottom.jpg'

const PhysiotherapyPage = () => {
  const locations = [
    {
      line1: 'Johannesgasse 25,',
      line2: '1010 Wien'
    },
    {
      line1: 'Alser Straße 49,',
      line2: '1080 Wien'
    },
    {
      line1: 'Kölgengasse 43,',
      line2: '1110 Wien'
    },
  ]
  return (
    <Layout pageTitle="BMI | Physiotherapie">
      <AboutTrainer trainer='Lasse' copy={physiotherapyCopy.about} lang img={img} locations={locations}/>
      <WhatNext copy={physiotherapyCopy.whatnext} lang/>
      <AdditionalInfo copy={physiotherapyCopy.info} img={bottomImg} lang/>
    </Layout>
  )
}

export default PhysiotherapyPage